import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

const Cohorts = () => {
  return (
    <>
      <div className="bg-gray-100" id="cohorts">
        <section className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 mt-20 pb-12">
          <div className="flex flex-col text-center w-full mb-4">
            <h2 className="text-base text-gray-900 tracking-widest uppercase font-medium mt-12 mb-4">
              Cohort-based courses
            </h2>
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 mb-2">
              We create immersive learning experiences.
            </h1>
          </div>

          <div className="md:flex">
            <div className="flex-1 p-4 border-2 border-gray-300 m-4 shadow-md rounded-sm">
              <h1 className="text-3xl font-bold tracking-tight text-purple-700 px-4 pt-4 text-center">
                Prototyping with Figma
              </h1>
              <p className="p-2 text-gray-700 text-center text-xl">
                Learn to build high-fidelity clickable prototypes in Figma in
                just two weeks.
              </p>
              <p className="text-gray-700 uppercase text-center text-sm font-bold">
                Starts March 21st, 2021
              </p>
              <div className="mt-3 sm:flex sm:justify-center pb-4">
                <div>
                  <div className="rounded-md shadow">
                    <Link
                      to="/courses/figma"
                      className="cursor-pointer w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-2 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-500 focus:outline-none focus:border-purple-700 focus:shadow-outline-purple transition duration-150 ease-in-out"
                    >
                      Learn more &#8250;
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 flex-1 border-2 border-gray-300 m-4 shadow-md rounded-sm">
              <h1 className="text-3xl font-bold tracking-tight text-red-700 px-4 pt-4 text-center">
                Intro to Web Development
              </h1>
              <p className="p-2 text-gray-700 text-center text-xl">
                Learn web development by building three unique projects in two
                weeks within a supportive community.
              </p>
              <p className="text-gray-700 uppercase text-center text-sm font-bold">
                Starts April 4th, 2021
              </p>
              <div className="mt-3 sm:flex sm:justify-center pb-4">
                <div>
                  <div className="rounded-md shadow">
                    <Link
                      to="/courses/web-development"
                      className="cursor-pointer w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-2 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition duration-150 ease-in-out"
                    >
                      Learn more &#8250;
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Cohorts;
