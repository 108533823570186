import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import PreviewProjects from "components/PreviewProjects";
import CodeEditor from "./CodeEditor";

const Value = () => {
  return (
    <>
      <div className="bg-white">
        <section className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <div className="text-center p-4">
            <div>
              <h1 className="text-4xl tracking-tight leading-snug font-extrabold text-gray-700 mt-4">
                Learn
              </h1>
              <h1 className="text-5xl tracking-tight leading-snug font-extrabold text-gray-800 mt-2">
                Start with a project
              </h1>
              <p className="mx-auto max-w-lg mt-4 text-xl text-gray-700">
                Learn by doing. Select a project in web development or data
                science that suits your goals and skill level the best.
              </p>
            </div>

            <PreviewProjects />

            <div className="mt-6">
              <h1 className="text-4xl tracking-tight leading-snug font-extrabold text-gray-700 mt-4">
                Build
              </h1>
              <h1 className="text-5xl tracking-tight leading-snug font-extrabold text-gray-800 mt-2">
                Apply your knowledge
              </h1>
              <p className="mx-auto max-w-lg mt-4 text-xl text-gray-700">
                Build your own projects to apply the concepts you've learned.
              </p>

              {/* <CodeEditor /> */}

              {/* <div className="md: max-w-xs max-w-full mt-8 text-gray-500">
                <p className="inline tracking-widest uppercase mr-2 ">HTML</p>{" "}
                <p className="inline tracking-widest uppercase mr-2">CSS</p>
                <p className="inline tracking-widest uppercase mr-2">
                  JavaScript
                </p>{" "}
                <p className="inline tracking-widest uppercase mr-2">
                  React.js
                </p>{" "}
                <p className="inline tracking-widest uppercase mr-2">Python</p>{" "}
                <p className="inline tracking-widest uppercase mr-2">Flask</p>{" "}
              </div> */}
            </div>

            <div className="mt-12 mb-3">
              <h1 className="text-4xl tracking-tight leading-snug font-extrabold text-gray-700 mt-4">
                Share
              </h1>
              <h1 className="text-5xl tracking-tight leading-snug font-extrabold text-gray-800 mt-2">
                Ship your work
              </h1>
              <p className="mx-auto max-w-lg mt-4 text-xl text-gray-700">
                Share your projects with our community for help, feedback, and
                more. Start building your project portfolio today.
              </p>

              <div>
                <div className="-space-x-4 mt-4">
                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/erica_headshot.jpg"
                    alt="Profile image"
                  />
                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/erin_headshot.png"
                    alt="Profile image"
                  />
                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/varun_headshot1.jpg"
                    alt="Profile image"
                  />
                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/nisreen_headshot.jpg"
                    alt="Profile image"
                  />
                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/nori_headshot.png"
                    alt="Profile image"
                  />
                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/liv_headshot.jpg"
                    alt="Profile image"
                  />
                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/oliver_headshot.jpg"
                    alt="Profile image"
                  />

                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/sam_headshot.jpg"
                    alt="Profile image"
                  />

                  <img
                    className="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/yaechan_headshot.jpg"
                    alt="Profile image"
                  />

                  <img
                    className="relative z-20 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/maxim_headshot.jpg"
                    alt="Profile image"
                  />
                  <img
                    className="relative z-30 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                    src="/img/cohort/samay_headshot.jpg"
                    alt="Profile image"
                  />
                </div>
                <p className="mx-auto max-w-lg mt-3 text-2xl text-gray-700">
                  Join our Discord community.
                </p>
                <span className="relative inline-flex mt-3">
                  <span className="inline-flex items-center px-4 py-2 text-base leading-6 font-medium rounded-md text-gray-800 bg-gray-200 hover:text-gray-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                    100+ online now
                  </span>
                  <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                  </span>
                </span>
                <br />
                <div className="rounded-md shadow-sm relative inline-flex mt-3">
                  <a target="_blank" href="https://discord.gg/8FwTpfN">
                    <button className="inline-flex items-center px-4 py-2 border border-gray-100 text-base leading-6 font-medium rounded-md text-gray-800 bg-white hover:text-gray-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                      Enter chat
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <div className="bg-white">
        <section className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <div className="p-4 mx-auto max-w-3xl mt-8 mb-4 text-center rounded">
              <h1 className="text-3xl tracking-tight leading-snug font-extrabold text-gray-700 mt-4">
                If you're new to building...
              </h1>
              <h1 className="text-4xl tracking-tight leading-snug font-extrabold text-gray-800 mt-2">
                Web Development Cohort
              </h1>
              <p className="mt-4 text-xl text-gray-700">
                Learn beginner web development concepts in just two weeks. Build
                three practical projects of your own and stay accountable with a
                supportive community.
              </p>
              <div className="mt-4 sm:mt-4 sm:flex justify-center">
                <div className="rounded-md shadow">
                  <Link to="/courses/web-development" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                    Learn more &#8250;
                  </Link>
                </div>
              </div>
          </div>
        </section>
      </div> */}
    </>
  );
};

export default Value;

// 1) Learn
// practical projects in web dev and data science
// OR take a cohort for more structure and accountability

// 2) Build

// Apply your knowledge
// work on these projects, extend them, make them your own
// example projects — list of a few of the best ones!

// 3) Share
// Ship projects with the world

//with the community

// Discord plug w/ 100+ people online
// Show off some amazing projects from creators in some sort of collage?

// End Stats — X Users, X Projects, X Completions, X Cohort Learners
