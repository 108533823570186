import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { getUserCount } from "api/db";
import { trackClick } from "constants/helpers";
import Typist from "react-typist";
import TypistLoop from "react-typist-loop";
import scrollTo from "gatsby-plugin-smoothscroll";

const Landing = () => {
  const [userCount, setUserCount] = useState(12000);
  useEffect(() => {
    getUserCount().then((snapshot) => {
      setUserCount(snapshot.val().count);
    });
  }, []);

  let typeOut = [
    "develop websites",
    "design applications",
    "prototype",
    "build with no code",
  ];

  return (
    <main className="relative mx-auto max-w-screen-xl">
      <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 z-10">
        <div className="sm:text-center lg:text-left">
          <Link
            to="/courses/figma"
            onClick={() => trackClick("landing header", "Web Dev Course")}
          >
            <div
              className="p-2 bg-blue-800 hover:bg-blue-700 items-center text-blue-100 leading-none rounded-full flex inline-flex mb-2 cursor-pointer"
              role="alert"
            >
              <span className="flex rounded-full bg-blue-600 uppercase px-2 py-1 text-xs font-bold mr-2 ml-2">
                New
              </span>
              <span className="font-semibold mr-1 text-left flex-auto text-sm">
                Prototyping with Figma Cohort
              </span>
              <svg
                className="fill-current opacity-75 h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
              </svg>
            </div>
          </Link>
        </div>
        <div className="sm:text-center lg:text-left">
          <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl lg:max-w-2xl ">
            Learn to
            <TypistLoop interval={1000}>
              {typeOut.map((word) => (
                <Typist key={word} avgTypingDelay={100} cursor={{ blink: true }}>
                  <span className="text-blue-600">
                    {word}
                  </span>
                  <Typist.Backspace
                    cursor={{ blink: true }}
                    count={word.length}
                    delay={2000}
                  />
                </Typist>
              ))}
            </TypistLoop>
          </h2>
          <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Enlight is the educational platform for cohort-based courses focused
            on development. We provide the best-in-class education paired with a
            supportive community and accountability.
          </p>

          <div className="md: max-w-xs max-w-full mt-6 text-gray-600">
            <p>
              Join{" "}
              <span className="text-gray-800 font-bold">
                {userCount.toLocaleString()}+
              </span>{" "}
              learners on Enlight.
            </p>
          </div>
          <div className="mt-4 sm:mt-4 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow cursor-pointer">
              <a
                onClick={() => {
                  scrollTo("#cohorts");
                }}
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
              >
                Explore cohorts &#8250;
              </a>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <Link to="/signup"
                 className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-blue-700 bg-gray-200 hover:text-blue-600 hover:bg-blue-50 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                  Sign up for free
              </Link>
            </div>
          </div>
        </div>
      </main>

      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 p-12 md:p-4 lg:block z-0">
        <img className="w-full h-full" src="/landing.svg" alt="" />
      </div>
      {/* <div className="top-0 absolute max-w-screen-xl h-760px bg-gradient-to-r from-teal-400 to-blue-500 transform -skew-y-12">
            <span className="h-190px"></span>
            <span className="h-190px"></span>
            <span className="h-190px"></span>
            <span className="h-190px w-1/3 bottom-0 bg-blue-500"></span>
          </div> */}
    </main>
  );
};

export default Landing;
