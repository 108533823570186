import React, { Component } from "react";

import Landing from "components/Landing/Landing";
import Cohorts from "components/Landing/Cohorts";
import Value from "components/Landing/Value";
import CTA from "components/Landing/CTA";

// import Community from "../components/Community";

import styled from "styled-components";
import { Row, Col } from "react-grid-system";

class LandingPage extends Component {
  render() {
    return (
      <div>
        <Landing />
        <Cohorts />

        {/* Move Value to the projects page! */}
        {/* <Value /> */}
        {/* <CTA /> */}
        {/* <Projects /> */}

        {/* <ProjectContainer>
          <Row>
            <Col lg={9} md={12} xs={12}>
              <h2 style={{ "margin-bottom": "16px"}}>Latest Projects</h2>
            </Col>
            <Col lg={3} md={12} xs={12}>
              <h2 style={{ "margin-bottom": "16px"}}>Featured Makers</h2>
              <Community />
            </Col>
          </Row>
        </ProjectContainer> */}
      </div>
    );
  }
}

export default LandingPage;
